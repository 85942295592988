
<script scoped>
    import { onBeforeUnmount } from "vue";
    import { getOrCreateDataObject, deleteDataObject } from "o365.vue.ts";

    const dsOrgUnits = getOrCreateDataObject({
        id: "dsOrgUnits:" + crypto.randomUUID(),
        viewName: "sviw_System_OrgUnitsChooserToolbar",
        fields: [
            { name: "ID" },
            { name: "OrgUnit" },
            { name: "Parent" },
        ],
    });

    function setMyContext(row) {

    }

    onBeforeUnmount(() => {
        deleteDataObject(dsOrgUnits);
    });
</script>

<template>
    <div>
        <div>
            <o-data-lookup
                class="form-control"
                :modelValue="contextName"
                :data-object="dsContextOrgUnits"
                :bind="sel => { setMyContext(sel); }"
                :title="$t('Context')"
            >
                <o-column field="ID" />
                <o-column field="OrgUnit" />
                <o-column field="Parent" />
            </o-data-lookup>
        </div>
    </div>
</template>
